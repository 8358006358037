<template>
  <div class="p-10 pr-16 all">
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-2/3 flex text-left text-c48 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
          />
          <div class="ml-4">
            Personnels <span v-if="user !== null">({{ user.length }})</span>
          </div>
        </div>

        <div class="w-1/3">
          <div class="flex justify-end">
            <div class="w-3/4">
              <bouton
                label="Ajouter un collaborateur"
                radius="5.4px"
                weight="600"
                size="17px"
                @oga="retourClick"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <global-view
            v-if="!reloadSession"
            type="commercial"
          @user="retourUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import globalView from '../../component/cdevs/personnel/globalData'

export default {
  name: "Index",

  components: {
    bouton,
    globalView
  },

  data () {
    return {
      icons: {
        back
      },
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      user: null,
    }
  },
  methods: {
    retourClick () {
     // this.$router.push('/createPersonnel')
    },

    retourCommerce (answer) {
      if (answer) {
        this.reloadSession = answer

        setTimeout(() => {
          this.reloadSession = false
        }, 200)
      }
      this.activeCommerciaux = false
    },

    retourUser (answer) {
      this.user = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all {
  min-height: 100vh;
}
</style>
