<template>
  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">
    <div class="mt-4">
      <div class="all">
        <div class="w-full flex text-90 text-c18 font-c5 text-left mb-6">
          <div class="w-1/3">
            Nom complet
          </div>
          <div class="w-1/5 ml-4">
            Contacts
          </div>
          <div class="w-1/4 ml-4">
            Email
          </div>
          <div class="w-1/6 ml-4">
            Position
          </div>
          <div class="w-20 ml-4"/>

        </div>

        <div class="diviser" />

        <div
          v-if="charge && devis.length === 0"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div class="text-center text-c18 text-60 mt-10"  v-if="!charge && devis.length === 0">Aucun {{type}} trouvé pour le moment</div>

        <div
          v-for="(item, index) in devis"
          :key="index"
          class="mt-5"
        >
          <div
            v-if="index > 0"
            class="diviser mt-5"
          />
          <div @click="selected(index)">
            <liste
              class="mt-5"
              type="commercial"
              :item="item"
              @delete="retourDelete"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    liste
  },

  props: {
    type: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down
      },
      devis: [],
      charge: true,
      position: null
    }
  },

  created () {
    this.allClient()
  },

  methods: {
    allClient () {
      let type = this.type
      if (this.type !== 'commercial'){
        type = 'DOCTOR'
      }
      http.get(apiroutes.baseURL + apiroutes.getCommerciaux + '?type=' + type.toUpperCase())
          .then(response => {
            console.log(response)
            this.devis = response
            this.charge = false
            this.$emit('user', response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            if (error.status === 401) {
              window.location.reload()
            }
          })
    },
    selected (index) {
      this.position = index
    },

    retourDelete () {
      if (this.position !== null) {
        this.devis.splice(this.position, 1)
        this.position = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
